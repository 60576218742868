import React from 'react'
import Button from '@mui/material/Button';
import Countdown from "react-countdown";
import hearts from "../../imgs/s-l400.png";
import shush from "../../imgs/1193606-200.png"
import styles from "./Love.module.css"

const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className={styles.count}>
        {days} days : {hours} hours : {minutes} minutes : {seconds} seconds
      </span>
    );
  }
};

function Love() {
  const [pixel , setPixel] = React.useState(0);
  const [counter , setCounter] = React.useState(0);
  console.log(counter + "ASd")
  return (<section className={styles.container}>
    { counter <= 2 ? <>
        {counter === 0 && <section className={styles.title}>Do you love me?</section> }
    {counter === 1 && <section className={styles.title}>Do you love me more than Tasiko?</section> }
    {counter === 2 && <section className={styles.title}>Are you ready to spend rest of your life with me?</section> }
    <section>
    <Button variant="contained" 
            onMouseOver={() => setPixel(Math.floor(Math.random()*150)) }
            onClick={() =>  setPixel(Math.floor(Math.random()*150))}
            style={{transform: `translate(${pixel}px , ${pixel}px )`}} >No</Button>
    <Button variant="contained" onClick={() => setCounter(prev => prev += 1)}>Yes</Button>
    </section>
    </> : <div className={styles.countdown}>
    <Countdown date={Date.now() + 85572000000} renderer={renderer} />
    <span className={styles.heart}>
    <img src={hearts} alt="heart"/>
    </span><span>
    <img src={shush} alt="heart"/>
    </span>
    </div>}
    </section>
  )
}

export default Love