import React from 'react'
import styles from "./General.module.css"
import cactusi from "../../imgs/cact.jpg"
import gif from "../../imgs/gifff.gif"
import picture from "../../imgs/picture.jpg"
import videoo from "../../imgs/Send this to someone on their birthday with no context🥳 (1).mp4"

function General() {
  return (
    <section className={styles.container}>
    <h4 className={styles.hh}>გილოცავ დაბადების დღეს!! მინდა ყველაზე ბედნიერი იყო ❤️ მეც ვეცდები, შემდეგ კი მივაღწევ  იმას რომ სიცოცხლის ყოველ წამს ბედნიერება გაჩუქო. დღეიდან მხოლოდ ღიმილს მინდა ვხედავდე შენს სახეზე, თვალებში სიცოცხლის ხალისს და გულში ჩემს სიყვარულს ❤️ მიყვარხარ ❤️</h4>
    <section className={styles.cactusi}>
        <img src={cactusi} alt="cactusi"/>
    </section>
    <h4>
    წარსულში რაც მოხდა წარსულშივე სჯობს დავტოვოთ და წინ გავიხედოთ. სახის ასეთ ცვლილებას აღარ გამოვიწვევ ❤️
    </h4>
    <section className={styles.gif}>
        <img src={gif} alt="gif"/>
    </section>
    <h4>იმედი მაქვს დღეს  მოსწავლეს გაკვეთილის გაცდენით გაახარებ მე კიდე ჩემთან ერთად ყოფნით ❤️</h4>
    <section className={styles.imgg}>
        <img src={picture} alt="pic"/>
    </section>
    <h4 className={styles.heartt}>From heart ❤️</h4>
    <section className={styles.video}>
        <video width="320" height="240" controls>
            <source src={videoo} type="video/mp4" />
    </video>
    </section>
    </section>
  )
}

export default General