import React from 'react'
import styles from './Quiz.module.css'
import Button from '@mui/material/Button';

const json = [
    {
        question: 'რა არის შენთვის სიყვარული?',
        choices:
        [
          { text: 'ადამიანის გვერდით გატარებული ყოველი წუთის კომფორტულად აღქმა', value: '10' },
          { text: 'უწყვეტი სულიერი, გონებრივი, ფიზიკური კავშირი', value: '20' },
          { text: 'ურთიერთგაგება, საუბარი, დათმობები, მეგობრობა', value: '30' },
          { text: 'ყველა ზემოთ ჩამოთვლილი', value: '40' }
        ]
      },
      {
        question: 'რა გიყვარს ჩემში ყველაზე მეტად?',
        choices:
        [
          { text: 'გარეგნობა ', value: '20' },
          { text: 'გონება ', value: '40' },
          { text: 'ხასიათი ', value: '30' },
          { text: 'რავი პროსტა გიყვარვარ', value: '10' }
        ]
      },
      {
        question: 'როგორ არ გაცივდება ურთიერთობა?',
        choices:
        [
          { text: 'არასოდეს გაცივდება იმიტომრომ ლავიუ ', value: '20' },
          { text: 'ვეცადოთ სიახლეები შევიტანოთ ', value: '40' },
          { text: 'ერთად მეტი დრო გავატაროთ ', value: '30' },
          { text: 'დავქორწინდეთ ', value: '10' }
        ]
      },
      {
        question: 'შემაფასე 1 დან 10 მდე',
        choices:
        [
          { text: '5-6', value: '10' },
          { text: '7-8', value: '20' },
          { text: '9', value: '30' },
          { text: '10', value: '40' }
        ]
      },
      {
        question: 'აირჩიე სად იცხოვრებდი ჩემთან ერთად',
        choices:
        [
          { text: 'თბილისი', value: '20' },
          { text: 'ქუთაისი', value: '30' },
          { text: 'საზღვარგარეთ', value: '10' },
          { text: 'სადაც მე ვიქნები იქ იქნები ბედნიერი', value: '40' }
        ]
      },
      {
        question: 'როგორ წარმოგიდგენია ჩვენი სიბერე?',
        choices:
        [
          { text: '50 წლის დაშორებულები ვიქნებით', value: '10' },
          { text: '3 შვილთან ერთად (2 ბიჭი, 1 გოგო)', value: '40' },
          { text: 'ერთად მაგრამ მარტო (კარგია სიწყნარე)', value: '20' },
          { text: 'ყოველ საღამოს Friends ის ახლიდან ყურება', value: '30' }
        ]
      },
      {
        question: 'რომელი უფრო საყვარელია?',
        choices:
        [
          { text: 'ტასო', value: '20' },
          { text: 'ვასო', value: '10' },
          { text: 'ოლიფანტე', value: '30' },
          { text: 'ვოლი', value: '40' }
        ]
      },
      {
        question: 'რისი შეცვლა გინდა ჩემში?',
        choices:
        [
          { text: 'უფრო დამყოლი ვიყო', value: '10' },
          { text: 'ნაკლები ტვინისბურღი ვიყო', value: '20' },
          { text: 'უნაკლო ვარ', value: '30' },
          { text: 'მნიშვნელოვანი არაფერი, ძალიან მინიმალური', value: '40' }
        ]
      },
      {
        question: 'როგორ ხვდები რომ მე ვარ The one?',
        choices:
        [
          { text: 'არ ვარ', value: '10' },
          { text: 'უბრალოდ გრძნობ', value: '30' },
          { text: 'ყველაზე კარგად შენ გიგებ', value: '20' },
          { text: 'ჩემს გარდა თავი არავისთან წარმოგიდგენია', value: '40' }
        ]
      },
      {
        question: 'რამდენად რთული ადამიანი ხარ?',
        choices:
        [
          { text: 'საერთოდ არა', value: '20' },
          { text: 'საშუალოდ', value: '30' },
          { text: 'რთული მაგრამ შენი ატანა უდიდესი პატივია', value: '40' },
          { text: 'როგორ გიძლებ საერთოდ', value: '10' }
        ]
      },
      {
        question: 'და ბოლოს, რას ვგრძნობ შენს მიმართ?',
        choices:
        [
          { text: 'მკიდიხარ', value: '10' },
          { text: 'ისე მიყვარხარ რომ აღწერა შეუძლებელია', value: '40' },
          { text: 'მიყვარხარ მაგრამ თან ნერვებს მიშლი', value: '30' },
          { text: 'მძულხარ ', value: '20' }
        ]
      },
]

const PollOption = ({ options, selected, onChange , question , num }) => {
    return (
      <section className={styles.poll}>
        <h1>{question}</h1>
        {options.map((choice, index) => (
            <section>
          <label key={index} className={styles.label}>
            <input type="radio"
              name={num}
              value={choice.value}
              key={index}
            //   checked={selected === choice.value}
              onChange={onChange} />
            {choice.text}
          </label>
          </section>
        ))}
      </section>
    );
  };

function Quiz() {
    const [selectedOption , setSelectedOption] = React.useState("")
    const [score , setScore] = React.useState(0)
    const [click , setClicked] = React.useState(false)
    const [error , setError] = React.useState("")
    const [eachScore , setEachScore] = React.useState({
        answer1: 0,
        answer2: 0,
        answer3: 0,
        answer4: 0,
        answer5: 0,
        answer6: 0,
        answer7: 0,
        answer8: 0,
        answer9: 0,
        answer10: 0,
        answer11: 0
    })
    function handleChange(e) {
        e.preventDefault()
        for (const property in eachScore) {
            if(eachScore[property] == 0) {
              setError("ბოლომდე შეავსეე")
              return ;
            }
            setScore(prev => prev += Number(eachScore[property]))
          }
        setClicked(true)
        if(error) setError("")
    }
    function tryagain() {
        setScore(0);
        setEachScore({
            answer1: 0,
            answer2: 0,
            answer3: 0,
            answer4: 0,
            answer5: 0,
            answer6: 0,
            answer7: 0,
            answer8: 0,
            answer9: 0,
            answer10: 0,
            answer11: 0
        });
        setClicked(false)
    }
    function handleOnChange(e, num) {
        setSelectedOption({ selectedOption: e.target.value});

        setEachScore({...eachScore ,  [`answer${num}`] : e.target.value})
      }
  return (
    <form onSubmit={handleChange} className={styles.form}>
        {click && score > 350 && <><h1>სხვანაირად არც ველოდი ❤</h1>{window.innerWidth <= 600 ? <><iframe src="https://giphy.com/embed/H4KMgh8z9FkJyEkIWi" width="300" height="300" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/molangofficialpage-party-wow-support-H4KMgh8z9FkJyEkIWi">via GIPHY</a></p> </>: <><iframe src="https://giphy.com/embed/H4KMgh8z9FkJyEkIWi" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/molangofficialpage-party-wow-support-H4KMgh8z9FkJyEkIWi">via GIPHY</a></p></> }<Button variant="contained" onClick={tryagain}>თავიდან სცადე</Button></>}
        {click && score < 350 && <><h1>კაი ხარ , მარა ჩქარი ხარ 🙂😘</h1>{window.innerWidth <= 600 ? <><iframe src="https://giphy.com/embed/l49JKwmJLChtS6d44" width="300" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/love-cute-l49JKwmJLChtS6d44">via GIPHY</a></p></> : <><iframe src="https://giphy.com/embed/l49JKwmJLChtS6d44" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/love-cute-l49JKwmJLChtS6d44">via GIPHY</a></p></>}<Button variant="contained" onClick={tryagain}>თავიდან სცადე</Button></>}
        {!click && <>
          <PollOption
          options={json[0].choices}
          onChange={(e) => handleOnChange(e , 1)}
          selected={selectedOption} 
          question={json[0].question}
          num="1"/>
          <PollOption
          options={json[1].choices}
          onChange={(e) => handleOnChange(e,2)}
          selected={selectedOption} 
          question={json[1].question}
          num="2"/>
          <PollOption
          options={json[2].choices}
          onChange={(e) => handleOnChange(e,3)}
          selected={selectedOption} 
          question={json[2].question}
          num="3"/>
          <PollOption
          options={json[3].choices}
          onChange={(e) => handleOnChange(e,4)}
          selected={selectedOption} 
          question={json[3].question}
          num="4"/>
          <PollOption
          options={json[4].choices}
          onChange={(e) => handleOnChange(e,5)}
          selected={selectedOption} 
          question={json[4].question}
          num="5"/>
          <PollOption
          options={json[5].choices}
          onChange={(e) => handleOnChange(e,6)}
          selected={selectedOption} 
          question={json[5].question}
          num="6"/>
          <PollOption
          options={json[6].choices}
          onChange={(e) => handleOnChange(e,7)}
          selected={selectedOption} 
          question={json[6].question}
          num="7"/>
          <PollOption
          options={json[7].choices}
          onChange={(e) => handleOnChange(e,8)}
          selected={selectedOption} 
          question={json[7].question}
          num="8"/>
          <PollOption
          options={json[8].choices}
          onChange={(e) => handleOnChange(e,9)}
          selected={selectedOption} 
          question={json[8].question}
          num="9"/>
          <PollOption
          options={json[9].choices}
          onChange={(e) => handleOnChange(e,10)}
          selected={selectedOption} 
          question={json[9].question}
          num="10"/>
          <PollOption
          options={json[10].choices}
          onChange={(e) => handleOnChange(e,11)}
          selected={selectedOption} 
          question={json[10].question}
          num="11"/>
          {error && <h1>{error}</h1>}
          <Button variant="contained" type='submit'>ვნახოთ აბა</Button>
        </>}
    </form>
  )
}

export default Quiz