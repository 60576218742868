import React from 'react'
import styles from "./Home.module.css"

function Home() {
  return (
    <section className={styles.container}>
      <h1>Birthday Girl! პაროლი გამოცნობილია. Enjoy!</h1>
    </section>
  )
}

export default Home