import './App.css';
import React from "react"
import Header from './components/Header/Header';
import {Routes , Route} from "react-router-dom";
import Home from "./components/Home/Home";
import Love from './components/Love/Love';
import Quiz from './components/Quiz/Quiz';
import General from './components/General/General';
import Button from '@mui/material/Button';

function App() {
  const [value , setValue] = React.useState("");
  const [click , setClick] = React.useState(false);
  const [error , setError] = React.useState("")
  return (
    <div className="App">
      {value === "Kldema" && click ? <div>
        <Header />
        <div className='routes'>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/love' element={<Love />}/>
          <Route path='/quiz' element={<Quiz />}/>
          <Route path='/general' element={<General />}/>
        </Routes>
        </div>
      </div> : <section className="passwords"><h3>Password : შენი nickname </h3><input type="text" placeholder="password" value={value} onChange={(e) => setValue(e.target.value)} /><Button variant="contained" onClick={() => {
        if(value === "Kldema") setClick(true)
        if(value !== "Kldema") setError("შეგირცხვა ნამუსი! ")
      }}>Submit</Button><h5>{error}</h5></section> }
    </div>
  );
}

export default App;
