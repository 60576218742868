import React from 'react'
import styles from "./Header.module.css"
import Button from '@mui/material/Button';
import cat from "../../imgs/cats.png";
import {Link} from "react-router-dom"

function Header() {
  return (
    <header className={styles.container}>
        <figcaption>
            <img src={cat} alt='cats' className={styles.imgg}/>
        </figcaption>
        <ul>
            <Link to="/love">
                <li><Button variant="contained">Love?</Button></li>
            </Link>
            <Link to="/quiz">
                <li><Button variant="contained">Quiz</Button></li>
            </Link>
            <Link to="/general">
                <li><Button variant="contained">From heart</Button></li>
            </Link>
        </ul>
    </header>
  )
}

export default Header